<template>
  <div v-if="!isApp">
    <transition name="fade">
      <div class="o-footer" v-if="button !== true">
        <div class="o-footer__inner">
          <div class="o-footer__content">
            {{ $t("footer.title") }}
          </div>
          <div class="o-footer__content">
            <a href="https://www.cdi.it/termini-di-utilizzo/" class="" target="_blank">
              {{ $t("footer.condizioni") }}</a>
            |
            <a href="https://www.cdi.it/privacy-policy/" class="" target="_blank">{{ $t("footer.privacy") }}</a>
            |
            <a href="https://www.cdi.it/cookie-policy/" target="_blank">Cookie Policy</a>
            |
            <a href="javascript: Cookiebot.renew()">Modifica consenso ai cookie</a>
            |
            <a href="https://www.cdi.it/il-centro-diagnostico-italiano/codice-etico-norme-e-trasparenza/"
              class="" target="_blank">{{ $t("footer.whistleblowing") }}</a>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="o-footer o-footer--start" v-if="button == true">
        <div class="o-footer__small" v-on:click="changeElement">
          <div class="o-footer__content o-footer__content--small">
            <img src="../../assets/solo-logo.png" alt="logo" class="o-footer__logo" /><span>CDI</span>
          </div>
          <div class="o-footer__content o-footer__content--small">
            <span class="o-footer__domanda">
              <img src="../../assets/domanda.png" alt="logo" />
            </span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      button: this.small,
    };
  },
  computed: {
    isApp() {
      const v = this.$route.query.app;
      if (v === "1")
        return true;
      return false;
    }
  },
  mounted() { },
  methods: {
    changeElement() {
      this.button = !this.button;
    }
  },
};
</script>
